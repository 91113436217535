<template>
    <Button 
        type="button"
        icon="pi pi-plus"
        label="Add"
        class="p-button-outlined"
        @click="openDialog()" 
    />
</template>

<script>
import Button from 'primevue/button';

export default {
    name: 'createProgram',
    components: {
        Button,
    },
    data() {
        return {
        }
    },
    methods: {
        openDialog() {
            // this.showDialog = true;
            this.$router.push('/admin/topics/program/new')
        },
    },
}
</script>
