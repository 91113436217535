<template>
    <!-- edit Category -->
    <Dialog 
        v-model:visible="showDialog"
        :style="{ width: '90%' }"
        header="Edit Programme"
        :modal="true"
        class="p-fluid"
        :maximizable="true"
        @hide="closeDialog"
    >
        <div class="field">
            <label for="name">Name</label>
            <InputText 
                id="name" 
                v-model.trim="form.name"
                @blur="v$.form.name.$touch"
                required="true" 
                autofocus
                :class="{ 'p-invalid': !form.name }" 
            />
            <small class="p-error" v-if="!form.name">Name is required.</small>
        </div>
        <template #footer>
            <Button 
                label="Cancel"
                icon="pi pi-times"
                class="p-button-text"
                @click="closeDialog"
            />
            <Button
                label="Save"
                icon="pi pi-check"
                class="p-button-text"
                :loading="editIsLoading"
                :disabled="this.v$.form.$invalid || editIsLoading"
                @click="edit()"
            />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
import { useToast } from 'vue-toastification'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
const toast = useToast()

export default {
    name: 'EditCategory',
    components: {
        Dialog,
        InputText,
        Button,
    },
    props: ['selectedElement'],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            editIsLoading: false,
            showDialog: false,
            form: {
                name: null
            },
        }
    },
    validations() {
        return {
            form: {
                name: { required, maxLength: maxLength(64) },
            },
        };
    },
    computed: {
    },
    methods: {
        closeDialog() {
            this.showDialog = false;
            this.form = {
                name: null,
            }
            this.$emit('closeDialog', 'edit')
        },
        async edit (){
            try {
                if(!this.v$.form.$invalid){
                    this.editIsLoading= true
                    await this.$store.dispatch('modifyProgram',{
                        documentProgramId: this.selectedElement.documentProgramId,
                        ...this.form
                    });
                    this.editIsLoading = false; 
                    // toast.success('Successfully updated.');
                    this.closeDialog();
                }
                else{
                    toast.error('wrong data');
                }
            } catch (error) {
                this.editIsLoading=false;
                toast.error(error.message);
            }
        }
    },
    watch: {
        selectedElement(newVal) {
            if (newVal) {
                this.showDialog = true;
                this.form = Object.assign({}, this.selectedElement);
            }
        }
    }
}

</script>