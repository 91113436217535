<template>
    <Dialog
      v-model:visible="showDialog"
      :style="{width: '450px'}"
      header="Delete Programme"
      :modal="true"
      @hide="closeDialog"
    >
    <div class="confirmation-content d-flex flex-row">
      <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
      <span>
          Are you sure you want to delete
          <span class="fw-bold">{{selectedElement.name}}</span>
      </span>
    </div>
      <template #footer>
          <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeDialog"
          />
          <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          :loading="deleteIsLoading"
          :disabled="deleteIsLoading"
          @click="deleteElement"
          />
      </template>
    </Dialog>
  </template>
  
  <script>
  import Dialog from "primevue/dialog";
  import Button from "primevue/button";
  
  
  export default {
      name:'DeleteCategory', 
      components:{
          Button,
          Dialog,
      }, 
      props: ['selectedElement'],
      data(){
          return{
              loading: true, 
              deleteIsLoading: false,
              showDialog:false,
          }
      },
      methods: {
          closeDialog(){
              this.showDialog = false;
              this.$emit('closeDialog', 'delete')
          },
          async deleteElement() {
              try {
                  this.deleteIsLoading = true;
                  await this.$store.dispatch('removeProgram',this.selectedElement.documentProgramId);
                  this.deleteIsLoading=false;
                  this.closeDialog();
              } catch (error) {
                  this.deleteIsLoading= false;
              }
          },
      },
      watch: {
          selectedElement(newVal){
              if(newVal){
                  this.showDialog = true;
              }
          }
      }
  }
  
  </script>