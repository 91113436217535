<template>
    <div class="container">
        <div class="row">
            <!-- <Button 
                class="p-button-raised p-button-rounded my-3 p-button-danger" 
                icon="pi pi-arrow-left"
                @click="goBack" 
            /> -->
            <div class="col-12 py-4">
                <ProgramsList :fromTopic="true" />
            </div>
        </div>
    </div>
</template>

<script>
// import Button from 'primevue/button';
import ProgramsList from './components/ProgramsList.vue';

export default {
    name: 'Program',
    components: {
        ProgramsList,
        // Button,
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    }
}
</script>
